<template>
  <div style="padding: 10px">
    <!--导入导出excel用-->
    <div style="margin: 10px 0" v-if="this.userId === 1">
      <el-upload
              action="http://182.92.223.123:9090/saltedFishTowerOther/import"
              :on-success="handleUploadSuccess"
              :show-file-list=false
              :limit="1"
              accept='.xlsx'
              style="display: inline-block; margin: 0 10px">
        <el-button type="primary">导入</el-button>
      </el-upload>
      <el-button type="primary" @click="add">新增</el-button>
      <!--<el-button type="primary" @click="exportUser">导出</el-button>-->
    </div>
    <!--搜索区域-->
    <div style="margin: 10px 0">
      <el-input v-model="search.towerLevel" placeholder="请输入咸将塔层数" @keyup.enter.native="load" style="width: 20%"
                clearable></el-input>
      <el-button type="primary" style="margin-left: 5px" @click="load">查询</el-button>
      <el-button style="margin-left: 5px" @click="resetData">重置</el-button>
      <el-button @click="$router.push('/login')" v-if="this.userId == undefined">返回登录</el-button>
    </div>
    <el-table
            v-loading="loading"
            :data="tableData"
            border
            stripe
            style="width: 100%">
      <el-table-column
              prop="towerLevel"
              width="100px"
              label="咸将塔层数">
      </el-table-column>
      <el-table-column
              prop="first"
              width="100px"
              label="1号位">
      </el-table-column>
      <el-table-column
              prop="second"
              width="100px"
              label="2号位">
      </el-table-column>
      <el-table-column
              prop="third"
              width="100px"
              label="3号位">
      </el-table-column>
      <el-table-column
              prop="fourth"
              width="100px"
              label="4号位">
      </el-table-column>
      <el-table-column
              prop="fifth"
              width="100px"
              label="5号位">
      </el-table-column>
      <el-table-column
              width="200px"
              prop="fromWhere"
              show-overflow-tooltip
              label="攻略来源">
      </el-table-column>
      <el-table-column
              prop="count"
              width="80px"
              label="失败次数">
      </el-table-column>
      <el-table-column
              prop="remark"
              show-overflow-tooltip
              label="备注">
      </el-table-column>
      <el-table-column label="操作" width="80px" v-if="this.userId === 1">
        <template #default="scope">
          <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div style="margin: 10px 0">
      <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[5, 10, 20]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
      </el-pagination>
    </div>
    <!--新增编辑表单-->
    <el-dialog title="新增/编辑" v-model="dialogVisible" width="30%">
      <el-form :model="form">
        <el-form-item label="层数">
          <el-input v-model="form.towerLevel" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item label="阵容（x分隔）">
          <el-input v-model="form.lineUp"></el-input>
        </el-form-item>
        <el-form-item label="攻略来源">
          <el-input v-model="form.fromWhere" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item label="失败次数">
          <el-input type="number" v-model="form.count" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" v-model="form.remark"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="save">确 定</el-button>
          </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>

  import request from "@/utils/request";

  export default {
    name: 'towerOtherFormation',
    components: {},
    data() {
      return {
        dialogVisible: false,
        form: {
          towerLevel: '',
          lineUp: '',
          count:'',
          remark: '',
          fromWhere: '',
        },
        userId: '',
        loading: true,
        search: {},
        query: {},
        currentPage: 1,
        pageSize: 10,
        total: 0,
        tableData: [],
      }
    },
    created() {
      this.load()
      let userStr = sessionStorage.getItem("user") || "{}"
      let user = JSON.parse(userStr)
      this.userId = user.id;
    },
    methods: {
      // 点击新增按钮
      add() {
        this.dialogVisible = true
        this.form = {}
      },
      handleEdit(row) {
        this.form = JSON.parse(JSON.stringify(row))
        this.dialogVisible = true
      },
      save() {
        this.loading = true;
        if (this.form.id) {  // 更新
          request.post("/saltedFishTowerOther/update", this.form).then(res => {
            if (res.code === '0') {
              this.$message({
                type: "success",
                message: "更新成功"
              })
            } else {
              this.$message({
                type: "error",
                message: res.msg
              })
            }
            this.load() // 刷新表格的数据
            this.loading = false
            this.dialogVisible = false  // 关闭弹窗
          })
        } else {  // 新增
          request.post("/saltedFishTowerOther/save", this.form).then(res => {
            if (res.code === '0') {
              this.$message({
                type: "success",
                message: "新增成功"
              })
            } else {
              this.$message({
                type: "error",
                message: res.msg
              })
            }
            this.form = '';
            this.load(); // 刷新表格的数据
            this.loading = false
            this.dialogVisible = false  // 关闭弹窗
          })
        }
      },
      handleUploadSuccess(res) {
        if (res.code === "0") {
          this.$message.success("导入成功")
          this.load()
        }
      },
      // exportUser() {
      //   location.href = "http://182.92.223.123:9090/saltedFishTowerOther/export";
      // },
      resetData() {
        this.search = {}
        this.load()
      },
      load() {
        this.loading = true
        const searchForm = {...this.search}
        let obj = Object.assign(searchForm, this.query)
        let reqData = {
          ...obj
        }
        reqData.current = this.currentPage
        reqData.size = this.pageSize
        request.get("/saltedFishTowerOther/list", {
          params: reqData
        }).then(res => {
          this.loading = false
          this.tableData = res.data.records
          this.total = res.data.total
        })
      },
      handleSizeChange(pageSize) {   // 改变当前每页的个数触发
        this.pageSize = pageSize
        this.load()
      },
      handleCurrentChange(pageNum) {  // 改变当前页码触发
        this.currentPage = pageNum
        this.load()
      }
    }
  }
</script>
